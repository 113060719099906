import React from 'react';

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

function isObject(objectToCheck) {
  return typeof objectToCheck === 'object'
}

const isString = value => typeof value === 'string';

function toTwitter(str) {
  if (!isString(str)) {
    return str;
  }

  if (str[0] === '@') {
    return str;
  }

  return `@${str}`;
}

function postToSeoOptions(
  page,
  pageSchema,
  siteSchema,
) {
  const { seo } = page;
  let pageSchemaObject = (
    isString(pageSchema) ? JSON.parse(pageSchema) : pageSchema
  )

  if (!isObject(pageSchemaObject) && isString(seo?.schema?.raw)) {
    pageSchemaObject = JSON.parse(seo?.schema?.raw ?? '');
  }

  const siteSchemaObject = (
    isString(siteSchema) ? JSON.parse(siteSchema) : siteSchema
  );

  const title =
    page.title ||
    seo?.title ||
    seo?.opengraphTitle ||
    seo?.twitterTitle ||
    siteSchemaObject?.siteName ||
    '';

  const description =
    seo?.metaDesc || seo?.opengraphDescription || seo?.twitterDescription || '';

  const canonical = seo?.canonical || seo?.opengraphUrl;
  const locale = page.locale || 'en_US';
  const cornerstone = seo?.cornerstone;
  const focusKeyword = seo?.focuskw;
  const keywords = seo?.metaKeywords;
  const readingTime = seo?.readingTime;

  const robotsIndex =
    seo?.metaRobotsNoindex === 'noindex' ? 'noindex' : 'index';
  const robotsFollow =
    seo?.metaRobotsNofollow === 'nofollow' ? 'nofollow' : 'follow';

  const image = {
    sourceUrl:
      seo?.opengraphImage?.sourceUrl ||
      seo?.twitterImage?.sourceUrl ||
      siteSchemaObject?.companyLogo?.sourceUrl,
    altText:
      seo?.opengraphImage?.altText ||
      seo?.twitterImage?.altText ||
      siteSchemaObject?.companyLogo?.altText,
    srcSet:
      seo?.opengraphImage?.srcSet ||
      seo?.twitterImage?.srcSet ||
      siteSchemaObject?.companyLogo?.srcSet,
  };

  return {
    title,
    description,
    locale,
    canonical,
    cornerstone,
    focusKeyword,
    keywords,
    pageSchema: pageSchemaObject,
    siteSchema: siteSchemaObject,
    readingTime,
    robots: {
      index: robotsIndex,
      follow: robotsFollow,
    },
    og: {
      title: seo?.opengraphTitle || title,
      description: seo?.opengraphDescription || description,
      image: (seo?.opengraphImage) || image,
      type: seo?.opengraphType || 'website',
      url: seo?.opengraphUrl || seo?.canonical,
      author: seo?.opengraphAuthor,
      publisher: seo?.opengraphPublisher,
      modifiedTime: seo?.opengraphModifiedTime,
      publishedTime: seo?.opengraphPublishedTime,
    },
    twitter: {
      title: seo?.twitterTitle || title,
      description: seo?.twitterDescription || description,
      image: (seo?.twitterImage) || image,
      creator: page?.author?.node?.seo?.social?.twitter,
    },
  };
}

export default function YoastSEO({
  meta,
  page,
  pageSchema,
  siteSchema,
  MetaRenderElement,
  processSchema,
}) {
  const seoOptions = postToSeoOptions(page || {}, pageSchema, siteSchema);

  const {
    title,
    description,
    canonical,
    locale,
    keywords,
    robots,
    og,
    twitter,
  } = seoOptions;

  let { pageSchema: pageSchemaObj, siteSchema: siteSchemaObj } = seoOptions;

  let pageSchemaStr = isObject(pageSchemaObj)
    ? JSON.stringify(pageSchemaObj)
    : undefined;
  let siteSchemaStr = isObject(siteSchemaObj)
    ? JSON.stringify(siteSchemaObj)
    : undefined;

  if (isFunction(processSchema)) {
    if (isString(pageSchemaStr)) {
      pageSchemaStr = processSchema(pageSchemaStr);
      pageSchemaObj = JSON.parse(pageSchemaStr);
    }

    if (isString(siteSchemaStr)) {
      siteSchemaStr = processSchema(siteSchemaStr);
      siteSchemaObj = JSON.parse(siteSchemaStr);
    }
  }

  return (
    <MetaRenderElement>
      {meta}
      <title>{title}</title>
      <meta
        name="robots"
        content={`max-snippet:-1, max-image-preview:large, max-video-preview:-1, index, ${robots.follow}`}
      />
      {isString(canonical) && <link rel="canonical" href={canonical} />}
      {isString(description) && (
        <meta name="description" content={description} />
      )}
      {isString(keywords) && <meta name="keywords" content={keywords} />}
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content={og?.type} />
      {isString(siteSchemaObj?.siteName) && (
        <meta property="og:site_name" content={siteSchemaObj?.siteName} />
      )}
      {isString(og?.title) && <meta property="og:title" content={og?.title} />}
      {isString(og?.description) && (
        <meta property="og:description" content={og?.description} />
      )}
      {isString(og?.author) && (
        <meta property="og:author" content={og?.author} />
      )}
      {isString(og?.url) && <meta property="og:url" content={og?.url} />}
      {isString(og?.publishedTime) && (
        <meta property="article.published_time" content={og?.publishedTime} />
      )}
      {isString(og?.modifiedTime) && (
        <meta property="article.modified_time" content={og?.modifiedTime} />
      )}
      {isString(og?.image?.sourceUrl) && (
        <>
          <meta property="og:image" content={og?.image?.sourceUrl} />
          {isString(og?.image?.altText) && (
            <meta property="og:image:alt" content={og?.image?.altText} />
          )}
        </>
      )}
      {isString(twitter?.title) && (
        <meta name="twitter:title" content={twitter?.title} />
      )}
      {isString(twitter?.description) && (
        <meta name="twitter:description" content={twitter?.description} />
      )}
      {isString(twitter?.image?.sourceUrl) && (
        <>
          <meta name="twitter:image" content={twitter?.image?.sourceUrl} />
          {isString(twitter?.image?.altText) && (
            <meta name="twitter:image:alt" content={twitter?.image?.altText} />
          )}
        </>
      )}
      {isString(twitter?.creator) && (
        <meta name="twitter:creator" content={toTwitter(twitter?.creator)} />
      )}
      {isString(pageSchemaStr) && (
        <script dangerouslySetInnerHTML={{ __html: pageSchemaStr }} type="application/ld+json"></script>
      )}
    </MetaRenderElement>
  );
}
